import { Component, OnInit, Input } from '@angular/core';
import { ChkService } from '../service/chk.service';
import { Router } from '@angular/router';
import {RestAPIService} from '../service/rest-api.service';
declare var $ : any;

@Component({
  selector: 'angly-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  /* Variables */
  homeContent    : any;
  services       : any;
  projectGallary : any;
  posts          : any;
  team           : any;
  pricingContent : any;
  contact        : any;
  videoContent   : any;
  consultancyContent : any;
  mobileFeatured : any;
  testimonial    : any;

  @Input() employeeDetails  = {
    name: '',
    email: '',
    phone:'',
    // salary: '',
    // age: 0
  };
  showMsg = false;

  constructor( private service:ChkService, public restApi: RestAPIService, public router: Router ) {

      this.service.getHomeContent().
         subscribe(response => {this.homeContent = response},
                   err      => console.log(err),
                   ()       => this.getContent(this.homeContent)
               );

      this.service.getServices().
         subscribe(response => {this.services = response},
                  err       => console.log(err),
                  ()        =>this.services
               );

      this.service.getPosts().
         subscribe(response => {this.posts = response},
                  err      => console.log(err),
                  ()       => this.posts
               );

      this.service.getProjectGallary().
         subscribe(response => {this.projectGallary = response},
                   err      => console.log(err),
                   ()       => this.projectGallary
               );


      this.service.getTeam().
         subscribe(response => {this.team = response},
                   err      => console.log(err),
                   ()       => this.team
               );

      this.service.getPricingPageContent().
         subscribe(response => {this.pricingContent = response},
                  err       => console.log(err),
                  ()        => this.pricingContent
               );

      this.service.getContactContent().
         subscribe(response => {this.contact = response},
                   err      => console.log(err),
                   ()       => this.contact
               );

      this.service.getHomeTestimonial().
         subscribe(response => {this.testimonial = response},
                   err      => console.log(err),
                   ()       => this.testimonial
               );
      }

  ngOnInit() {
    // $(document).ready(() =>{
    //   setTimeout(() => {
    //       $('#subscriptionModal').modal('show');
    //   }, 20000);
    // });

    // $(document).ready(() => {
    //   setTimeout(() => {
    //     if($.cookie("kailashs") == null) {
    //         $('#subscriptionModal').modal('show');
    //         $.cookie("kailashs", "2");
    //     }
    //   }, 1000);
    // });


  }


  /*
   * getContent is used for get the home page content.
   * Used variables is videoContent and mobileFeatured.
   */
   getContent(content)
   {
      this.videoContent    = content.video_content;
      this.consultancyContent    = content.consultancy_content;
      this.mobileFeatured  = content.mobile_featured;
   }

   subscribeFormClasses : any = {rowClass:"row", fieldClass:"col-sm-12 col-md-6"}




  //  Subscribe Now Form
  addEmployee() {
    console.log('Button clicked...');
    console.log('Form submitted...');
    this.showMsg= true;
  //   let employee = {
  //     "star_value": "",
  //     "lead_score": "",
  //     "tags": [
  //         "Lead",
  //         "Likely Buyer"
  //     ],
  //     "properties": [
  //         {
  //             "type": "SYSTEM",
  //             "name": "first_name",
  //             "value": "Samson"
  //         },
  //         {
  //             "type": "SYSTEM",
  //             "name": "last_name",
  //             "value": "Nolan"
  //         },
  //         {
  //             "type": "SYSTEM",
  //             "name": "email",
  //             "subtype": "work",
  //             "value": "samson@walt.ltd"
  //         },
  //         {
  //             "type": "SYSTEM",
  //             "name": "address",
  //             "value": "{\"address\":\"225 George Street\",\"city\":\"NSW\",\"state\":\"Sydney\",\"zip\":\"2000\",\"country\":\"Australia\"}"
  //         },
  //         {
  //             "name": "phone",
  //             "value": "8888888889",
  //             "subtype": "work"
  //         },
  //         {
  //             "name": "phone",
  //             "value": "8888888889",
  //             "subtype": "home"
  //         },
  //         {
  //             "name": "website",
  //             "value": "www.youtube.com",
  //             "subtype": "YOUTUBE"
  //         },
  //         {
  //             "name": "website",
  //             "value": "www.linkedin.com",
  //             "subtype": "LINKEDIN"
  //         },
  //         {
  //             "name": "website",
  //             "value": "www.mywebsite.com",
  //             "subtype": "URL"
  //         },
  //         {
  //             "name": "My custom field of type text",
  //             "type": "CUSTOM",
  //             "value": "My name is ghanshyam"
  //         },
  //         {
  //             "name": "My custom field of type date",
  //             "type": "CUSTOM",
  //             "value": 1479580200
  //         },
  //         {
  //             "name": "My custom field of type checkbox",
  //             "type": "CUSTOM",
  //             "value": "on"
  //         },
  //         {
  //             "name": "My custom field of type list",
  //             "type": "CUSTOM",
  //             "value": "lemon"
  //         },
  //         {
  //             "name": "My custom field of type companies",
  //             "type": "CUSTOM",
  //             "value": "[\"5767466600890368\",\"5114076984246272\",\"5746725247516672\"]"
  //         }
  //     ]
  // };
    // this.restApi.createEmployee({}).subscribe((data: {}) => {
    //   console.log('Form submitted...');
    //   this.showMsg= true;
    //   console.log('Contact Created!')
    // })
  }






}
