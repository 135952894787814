import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-java-spring-boot',
  templateUrl: './java-spring-boot.component.html',
  styleUrls: ['./java-spring-boot.component.scss']
})
export class JavaSpringBootComponent implements OnInit {
  
  /* Variables */
  title = 'Java Spring Boot - Encriss Technologies';
  featuresContent : any;
  springbootportfolio : any;
  springbootImage_path1="./assets/img/services/springboot/springboot-section2.png";
  springbootImage_altText="Java Spring Boot Application And Services";

  constructor(
    private pageTitleService: PageTitleService,
    private service:ChkService,
    private titleService: Title,
    private meta: Meta
    ) {

    /* Page title */
    this.pageTitleService.setTitle(" Java Spring Boot ");

    /* Page subTitle */
    // this.pageTitleService.setSubTitle(" Our latest news and learning articles. ");

     //  Background Image URL
   this.pageTitleService.setBgImg("../assets/img/services/springboot/java.jpg");

    this.service.getSpringBootContent().
      subscribe(response => {this.featuresContent = response},
                err => console.log(err),
                () => this.featuresContent
            );
    this.service.getSpringbootPortfolio().
         subscribe(response => {this.springbootportfolio = response},
                     err    => console.log(err),
                     ()     => this.springbootportfolio
                  );

  }


  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'keywords',
      content: 'Java Spring Boot,Spring Boot, Spring Framework, Java Spring , Spring Boot Microservices, Java Spring Boot In India, Angular Java, Spring Boot Angular, Spring Boot MongoDB, Spring Boot Postgresql, Spring Boot Tomcat, Spring Boot Application, Java Spring Boot Microservices, Spring MVC, Java Spring Boot Implementation, Spring boot Companies in India, Software development with Spring boot and Camunda, Spring Hibernate Implementation, Application Implementation with Java Spring boot, Low Cost Spring Implementation'
    });
  }
}
