import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-mobile-applications',
  templateUrl: './mobile-applications.component.html',
  styleUrls: ['./mobile-applications.component.scss']
})
export class MobileApplicationsComponent implements OnInit {
  /* Variables */
  title= 'Mobile Applications - Encriss Technologies';
  featuresContent : any;

  constructor(
    private pageTitleService: PageTitleService, 
    private service:ChkService,
    private titleService: Title,
    private meta: Meta
    ) {

    /* Page title */
    this.pageTitleService.setTitle(" Mobile Applications ");

     //  Background Image URL
     this.pageTitleService.setBgImg("../assets/img/services/mobileapplications/mobile.jpg");

     this.service.getMobileData().
     subscribe(response => {this.featuresContent = response},
               err => console.log(err),
               () => this.featuresContent
           );
  }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name:'keywords',
      content: 'mobile app development company, android app development services, iphone app development companies, cross platform app development, mobile app development services, mobile application development company, react native apps, hybrid app development company, cross platform mobile app development, mobile application development services, hybrid application, top mobile app development companies, native mobile app, hybrid mobile app development, android application development company, mobile app development agency, application development company, android development company, best android app development company, enterprise app development, ios app developers, mobile application developers, hybrid mobile application, native mobile app development, ecommerce app development company, healthcare mobile app development, mobile app agency, enterprise mobile app development, enterprise mobile application development, android mobile app development company, enterprise app development company, mobile app development firm, mobile development services,mobile application company'
    });
  }

}
