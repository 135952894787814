import { Injectable } from '@angular/core';

/*
 * Menu interface
 */
export interface Menu {
	state: string;
	name?: string;
	type?: string;
	icon?: string;
	children?: ChildrenItems[];
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
  	state: string;
  	name: string;
  	type?: string;
}

const HEADERMENUITEMS = [
   {
      state: "home",
      name: "Home",
      type:"link"
   },
   {
      state: "",
      name: "Our Services",
      type:"sub",
      icon: 'fa fa-caret-down',
      children: [
         // { state: 'java-spring-boot', name: 'Java SpringBoot', type:"link", link:"https://encriss.com/encriss/java-springboot-details"},
         // { state: 'web-applications', name: 'Web Applications', type:"link", link:"https://encriss.com/encriss/web-application-details"},
         // { state: 'mobile-applications', name: 'Mobile Applications', type:"link", link:"https://encriss.com/encriss/mobile-application-details"},
         // { state: 'erp-implementation', name: 'ERP Implementation', type:"link", link:"https://encriss.com/encriss/erp-implementation-details"},
         { state: 'temenos', name: 'Temenos', type:"link" , link: "temenos"},
         { state: 'virtual-cto', name: 'Virtual CTO', type:"link" , link: "virtual-cto"},
         { state: 'java-spring-boot', name: 'Java SpringBoot', type:"link" , link: "java-spring-boot"},
         { state: 'web-applications', name: 'Web Applications', type:"link", link:"web-applications"},
         { state: 'mobile-applications', name: 'Mobile Applications', type:"link", link:"mobile-applications"},
         { state: 'erp-implementation', name: 'ERP Implementation', type:"link", link:"erp-implementation"},
         { state: 'sap-hana', name: 'SAP Hana', type:"link", link: "sap-hana"},
         { state: 'powerBI', name: 'Power BI', type:"link", link: "powerBI"},
         { state: 'ai', name: 'Artificial Intelligence', type:"link", link:"ai"},
         { state: 'bigData', name: 'Big Data', type:"link", link:"bigData"},
         { state: 'digital-marketing', name: 'Digital Marketing', type:"link", link:"digital-marketing"},
         { state: 'business-process', name: 'Business Process Management', type: 'link', link:'business-process' }
      ]
   },
   {
    state: "",
    name: "Careers",
    type:"link-page",
    link:"https://encriss.com/encriss/careers/"
   },
   {
      state: "",
      name: "Blogs",
      type:"link-page",
      link:"https://encriss.com/encriss/blog-2/"
   },
  //  {
  //     state: "",
  //     name: "Products",
  //     type:"mega2",
  //     icon: 'fa fa-caret-down',
  //     children: [
  //        { state: '', name: 'Image_holder1', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/4.jpg",
  //              state:"ecommerce",
  //              type:"link",
  //              name:"E-commerce Platform",
  //              content: "Enhance your test automation capabilities with our codeless test automation tool",
  //              link:"ecommerce",

  //           }
  //        ]},
  //        { state: '', name: 'Image_holder2', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/6.jpg",
  //              state:"sportERP",
  //              type:"link",
  //              name:"Sports ERP",
  //              content: "One solution for all signatures: Electronic. Digital. Biometric.",
  //              link:"sportERP",

  //           }
  //        ]},
  //        { state: '', name: '', type:"sub", icon: 'fa fa-caret-down',children:[
  //           {
  //              state:"manufacturingERP", name:"Manufacturing ERP Molecules", type:"link", link:"manufacturingERP"
  //           },
  //           {
  //              state:"gsuite", name:"G-Suite", type:"link", link:"gsuite"
  //           },
  //           {
  //              state:"learning-management", name:"Learning Management", type:"link", link:"learning-management"
  //           }
  //        ]}

  //     ]
  //  },
  //  {
  //     state: "",
  //     name: "Services",
  //     type:"mega",
  //     icon: 'fa fa-caret-down',
  //     children: [
  //        { state: '', name: 'Image_holder1', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/1.jpg",
  //              state:"product-engineering",
  //              type:"link",
  //              name:"Product Engineering",
  //              content: "Accelerate innovation, time to market and deliver improved customer experience",
  //              link:"product-engineering",

  //           },
  //           {
  //              path: "../../../assets/img/menu/5.jpg",
  //              state:"business-process",
  //              type:"link",
  //              name:"Business Process Management 2.0",
  //              content: "Design, develop & integrate customized solutions using Microsoft technologies",
  //              link:"business-process",
  //           }
  //        ]},
  //        { state: '', name: 'Engineering Services', type:"sub", icon: 'fa fa-caret-down',children:[
  //           {
  //              state:"java-spring-boot", name:"Application Development", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Application Testing", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Content Management Services", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Consultancy Services", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"DevOps", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Frontend Engineering", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Implementation Services", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Managed Services", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Product Engineering", type:"link", link:"java-spring-boot"
  //           }
  //        ]},
  //        { state: '', name: 'Technology Services', type:"sub", icon: 'fa fa-caret-down', children:[
  //           {
  //              state:"java-spring-boot", name:"Java", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Springboot", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Camunda", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Pega", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"OpenText", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"NodeJS", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"MeanStack", type:"link", link:"java-spring-boot"
  //           }
  //        ]}

  //     ]
  //  },
  //  {
  //     state: "",
  //     name: "Solutions",
  //     type:"mega2",
  //     icon: 'fa fa-caret-down',
  //     children: [
  //        { state: '', name: 'Image_holder1', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/7.jpg",
  //              state:"java-spring-boot",
  //              type:"link",
  //              name:"Robotic Process Automation",
  //              content: "Enhance your test automation capabilities with our codeless test automation tool",
  //              link:"java-spring-boot",

  //           }
  //        ]},
  //        { state: '', name: 'Image_holder2', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/1.jpg",
  //              state:"digital-marketing",
  //              type:"link",
  //              name:"Digital Marketing",
  //              content: "One solution for all signatures: Electronic. Digital. Biometric.",
  //              link:"digital-marketing",

  //           }
  //        ]},
  //        { state: '', name: '', type:"sub", icon: 'fa fa-caret-down',children:[
  //           {
  //              state:"java-spring-boot", name:"Artificial Intelligence", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Blockchain", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Cloud", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Internet Of Things(IOT)", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Robotic Process Automation", type:"link", link:"java-spring-boot"
  //           }
  //        ]}

  //     ]
  //  },
  //  {
  //     state: "",
  //     name: "Resources",
  //     type:"mega2",
  //     icon: 'fa fa-caret-down',
  //     children: [
  //        { state: '', name: 'Image_holder1', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/7.jpg",
  //              state:"java-spring-boot",
  //              type:"link",
  //              name:"Robotic Process Automation",
  //              content: "Enhance your test automation capabilities with our codeless test automation tool",
  //              link:"java-spring-boot",

  //           }
  //        ]},
  //        { state: '', name: 'Image_holder2', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/1.jpg",
  //              state:"digital-marketing",
  //              type:"link",
  //              name:"Success Stories",
  //              content: "Our award winning client deliveries",
  //              link:"digital-marketing",

  //           }
  //        ]},
  //        { state: '', name: '', type:"sub", icon: 'fa fa-caret-down',children:[
  //           {
  //              state:"java-spring-boot", name:"News", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Success Stories", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"eBooks", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"", name:"Blogs", type:"link-page", link:"https://encriss.com/encriss/blog-2"
  //           },
  //           {
  //              state:"java-spring-boot", name:"White Papers", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Webinars", type:"link", link:"java-spring-boot"
  //           }
  //        ]}

  //     ]
  //  },
  //  {
  //     state: "",
  //     name: "About Us",
  //     type:"mega2",
  //     icon: 'fa fa-caret-down',
  //     children: [
  //        { state: '', name: 'Image_holder1', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/8.jpg",
  //              state:"java-spring-boot",
  //              type:"link",
  //              name:"Leadership Team",
  //              content: "Enhance your test automation capabilities with our codeless test automation tool",
  //              link:"java-spring-boot",

  //           }
  //        ]},
  //        { state: '', name: 'Image_holder2', type:"image", children:[
  //           {
  //              path: "../../../assets/img/menu/9.jpg",
  //              state:"digital-marketing",
  //              type:"link",
  //              name:"Company Overview",
  //              content: "Our award winning client deliveries",
  //              link:"digital-marketing",

  //           }
  //        ]},
  //        { state: '', name: '', type:"sub", icon: 'fa fa-caret-down',children:[
  //           {
  //              state:"java-spring-boot", name:"Company Overview", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Leadership Team", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Technology Partners", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Accreditations", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"GDPR Compliance", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Testimonials", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Engagement Models", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"java-spring-boot", name:"Sustainability", type:"link", link:"java-spring-boot"
  //           },
  //           {
  //              state:"contact", name:"Contact Us", type:"link", link:"contact"
  //           }
  //        ]}

  //     ]
  //  },
  //  {
  //     state: "careers",
  //     name: "Careers",
  //     type:"link"
  //  },
   {
      state: "contact",
      name: "Contact Us",
      type:"link"
   }
   ];


   const FOOTERMENU2 = [
      {
        state: 'temenos',
        name: 'Temenos',
        type:"link" ,
        footerLink: "temenos"
      },
      {
        state: 'virtual-cto',
        name: 'Virtual CTO',
        type:"link" ,
        footerLink: "virtual-cto"},
      {
         state: "java-spring-boot",
         name: "Java Spring Boot",
         type:"link",
         // footerLink:"https://encriss.com/encriss/java-springboot-details"
         footerLink:"java-spring-boot"
      },
      {
         state: "web-applications",
         name: "Web Application",
         type:"link",
         // footerLink:"https://encriss.com/encriss/web-application-details"
         footerLink:"web-applications"
      },
      {
         state: "mobile-applications",
         name: "Mobile Apps",
         type:"link",
         // footerLink:"https://encriss.com/encriss/mobile-application-details"
         footerLink:"mobile-applications"
      },
      {
         state: "erp-implementation",
         name: "ERP Implementation",
         type:"link",
         // footerLink:"https://encriss.com/encriss/erp-implementation-details"
         footerLink:"erp-implementation"
      },
      {
         state: "sap-hana",
         name: "SAP Hana",
         type: "link",
         footerLink: "sap-hana"
      },
      {
         state: "powerBI",
         name: "PowerBI",
         type: "link",
         footerLink: "powerBI"
      },
      {
         state: 'digital-marketing',
         name: 'Digital Marketing',
         type:"link",
         footerLink:"digital-marketing"
      },
      {
         state: 'business-process',
         name: 'Business Process Management',
         type:"link",
         footerLink:"business-process"
      }


   ];



   const HEADERMENUITEMS2 = [
      {
         state: "home",
         name: "Home",
         type:"link"
      },
      {
         state: "our-services",
         name: "Our Services",
         type:"sub",
         icon: 'fa fa-caret-down',
         children: [
            // { state: 'java-spring-boot', name: 'Java SpringBoot', type:"link", link:"https://encriss.com/java-springboot-details"},
            { state: 'java-spring-boot', name: 'Java SpringBoot', type:"link"},
            { state: 'web-applications', name: 'Web Applications', type:"link", link:"https://encriss.com/web-application-details"},
            { state: 'mobile-applications', name: 'Mobile Applications', type:"link", link:"https://encriss.com/mobile-application-details"},
            { state: 'erp-implementation', name: 'ERP Implementation', type:"link", link:"https://encriss.com/erp-implementation-details"},
            { state: 'ai-&-bi', name: 'AI & BI', type:"link", link:"javascript:void(0);"},
            { state: 'digital-marketing', name: 'Digital Marketing', type:"link", link:"javascript:void(0);"}
         ]
      },
      {
         state:"",
         name:"Pages",
         type:"sub",
         icon: 'fa fa-caret-down',
         children: [
            { state: 'about', name: 'About', type:"link"},
            { state: 'features', name: 'Features', type:"link"},
            { state: 'contact', name: 'Contact', type:"link"},
            { state: 'support', name: 'Support', type:"link"},
            { state: 'pricing', name: 'Pricing', type:"link"},
            { state: 'search', name: 'Search', type:"link"},
            { state: 'portfolio-v1', name: 'Portfolio V1', type:"link"},
            { state: 'portfolio-v2', name: 'Portfolio V2', type:"link"},
            { state: 'portfolio-v3', name: 'Portfolio V3', type:"link"},
            { state: 'testimonial-v1', name: 'Testimonial V1', type:"link"},
            { state: 'testimonial-v2', name: 'Testimonial V2', type:"link"}
         ]
      },
      {
         state:"",
         name:"Features",
         type:"sub",
         icon: 'fa fa-caret-down',
         children: [
            { state: 'sidebar-widgets', name:'Widgets', type:"link"},
            { state: 'login', name:'Login', type:"link"},
            { state: 'sign-up', name: 'Sign Up', type:"link"},
            { state: 'thank-you', name: 'Thank You', type:"link"},
            { state: 'maintenance', name: 'Maintenance', type:"link"},
            { state: 'not-found', name: '404', type:"link"}
         ]
      },
      {
         state:"",
         name:"Shop",
         type:"sub",
         icon: 'fa fa-caret-down',
         children: [
            { state: 'product-list', name:'Product List', type:"link"},
            { state: 'product-cart', name: 'Product Cart', type:"link"},
            { state: 'product-checkout', name: 'Product Checkout', type:"link"},
            { state: 'product-detail', name: 'Product Detail', type:"link"}
         ]
      },
      {
         state:"",
         name:"Blog",
         type:"sub",
         icon: 'fa fa-caret-down',
         children: [
            { state: 'blog-listing-sidebar', name:'blog column ', type:"link"},
            { state: 'blog-column2', name: 'Blog Column2', type:"link"},
            { state: 'blog-column3', name: 'Blog Column3', type:"link"},
            { state: 'blog-masonry2', name: 'Blog Masonry2', type:"link"},
            { state: 'blog-masonry3', name: 'Blog Masonry3', type:"link"},
            { state: 'blog-sidebar', name: 'Blog Sidebar', type:"link"},
            { state: 'blog-no-sidebar', name: 'Blog No Sidebar', type:"link"},
            { state: 'blog-detail', name: 'Blog Detail', type:"link"}
         ]
      },
      {
         state: "contact",
         name: "Contact Us",
         type:"link"
      }
      ];





const FOOTERMENU = [
   {
      state: "home",
      name: "Home",
      type:"link"
   },
   {
      state:"features",
      name:"Features",
      type:"link"
   },
   {
      state:"pricing",
      name:"Pricing",
      type:"link"
   },
   {
      state:"contact",
      name:"Contact",
      type:"link"
   },
   {
      state:"team",
      name:"Team",
      type:"link"
   },
   {
      state:"about",
      name:"About",
      type:"link"
   }
]

const EXPLOREMENU = [
   {
      state: "home",
      name: "Dashboard",
      type:"link"
   },
   {
      state: "sign-in",
      name: "Sign In",
      type:"link"
   },
   {
      state: "sign-up",
      name: "Sign Up",
      type:"link"
   },
   {
      state: "helpdesk",
      name: "Helpdesk",
      type:"link"
   },
   {
      state: "privacy-policy",
      name: "Privacy Policy",
      type:"link"
   },
   {
      state: "terms-conditions",
      name: "Terms & Conditions ",
      type:"link"
   }
];


const FOOTERMENU3=[
   {
      state: "home",
      name: "Home",
      type:"link"
   },
   {
      state:"sidebar-widgets",
      name:"Widgets",
      type:"link"
   },
   {
      state:"about",
      name:"About",
      type:"link"
   },
   {
      state:"contact",
      name:"Contact",
      type:"link"
   },
   {
      state:"features",
      name:"Features",
      type:"link"
   },
   {
      state:"support",
      name:"Support",
      type:"link"
   },
   {
      state:"search",
      name:"Search",
      type:"link"
   }
];

@Injectable()
export class MenuItems {

   /*
    * Get all header menu
    */
   getMainMenu(): Menu[] {
      return HEADERMENUITEMS;
   }

   /*
    * Get footer menu
    */
   getFooterMenu(): Menu[] {
      return FOOTERMENU;
   }

   /*
    * Get the explore menu
    */
   getExploreMenu(): Menu[] {
      return EXPLOREMENU;
   }

   /*
    * Get the footer2
    */
   getFooter2(): Menu[] {
      return FOOTERMENU2;
   }

}
