import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';


@Component({
  selector: 'app-kia',
  templateUrl: './kia.component.html',
  styleUrls: ['./kia.component.css']
})
export class KiaComponent implements OnInit {

  /* Variables */
  featuresContent : any;

  constructor(private pageTitleService: PageTitleService, private service:ChkService) {

    /* Page title */
    this.pageTitleService.setTitle(" KIA Finance ");

    /* Page subTitle */
    this.pageTitleService.setSubTitle(" Our latest news and learning articles. ");

    this.service.getFeaturesContent().
      subscribe(response => {this.featuresContent = response},
                err => console.log(err),
                () => this.featuresContent
            );

  }

  


  ngOnInit() {
  }

}
