import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-business-process-mgmt',
  templateUrl: './business-process-mgmt.component.html',
  styleUrls: ['./business-process-mgmt.component.scss']
})
export class BusinessProcessMgmtComponent implements OnInit {
/* Variables */
title = 'Business Process Management - Encriss Technologies';
featuresContent : any;
constructor(
  private pageTitleService: PageTitleService,
  private service:ChkService,
  private titleService: Title,
  private meta: Meta
  ) {

  /* Page title */
  this.pageTitleService.setTitle(" Business Process Management ");

  /* Page subTitle */
  // this.pageTitleService.setSubTitle(" Our latest news and learning articles. ");

  //  Background Image URL
 this.pageTitleService.setBgImg("../assets/img/services/digitalMarketing/digitalMarketing.jpg");

  this.service.getBPMData().
    subscribe(response => {this.featuresContent = response},
              err => console.log(err),
              () => this.featuresContent
          );
}
ngOnInit() {
  this.titleService.setTitle(this.title);
  this.meta.updateTag({
    name: 'keywords',
    content: 'Web Application Company, Top Website Designing Company,Best Web Development Companies, Website Design Company in India, Web Development Service in India, Web Solutions Companies, Top Web Design Companies, Web Development Company in Delhi, Website Design Agency, Web Applications, Web Portal, Mobile App Development, Mobile Application Development India, App Development Company, Mobile App Developers, Mobile App Development Company India, Mobile Apps Development Services, Mobile Application Developer, Android App Development Cost, Mobile App Development, App Development India, Mobile App Developers India, Mobile Apps Development Company Gurgaon, Mobile App Workflow, Best Mobile Apps Development in India, Mobile Apps Development NCR, Andriod App Workflow, Cutting Edge IOS App, Ios App Work Flow, Ios App Design, Ios App Development, Cutting Edge App, Best IOS Mobile Apps, All in One Software Development Services, Best Erp Solution in India, Best Software Development Company in India, Java Spring Boot, Java Spring Boot Development in India, Spring Boot Implementation, Java Spring Boot Company in India, Spring Boot Java T Point, Spring Framework Module in India, New Development Model in India, Spring Boot Documentation, Online Digital Marketing, SEO Experts in India, , Digital Agency in Delhi, Digital Agency in India, Digital Marketing Company Gurgaon, Social Media Marketing, Top10 Digital Marketing Company, Best Digital Marketing Company in India, E-Commerce Platform For Online Stores, Best Ecommerce Platform, B2B Ecommerce Platform, Top Ecommerce Platform, Ecommerce Store, Best Ecommerce Platform For Startups, Ecommerce Mobile App'
  });
}

}
