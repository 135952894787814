import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contact } from '../shared/contact';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestAPIService {
  // userName ='sgarg@encriss.com';
  // apiKey= 'ao80fghsoa5v9dqbvs6bfha9pn';

  // apiURL = 'https://dummy.restapiexample.com/api/v1';
  apiURL = 'https://encriss.agilecrm.com/dev';
  constructor(private http: HttpClient) { }

  // Http Options
   username = 'sgarg@encriss.com';
   password = 'ao80fghsoa5v9dqbvs6bfha9pn';


   BasicAuth = 'Basic ' + btoa(this.username + ':' + this.password);
   httpBasicAuthOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: this.BasicAuth
    })
  };

  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   }),
  //   'Header set Access-Control-Origin': '*',
  //   'Header always set Access-Control-Allow-Methods' : "POST, GET, OPTIONS, DELETE, PUT",
  //   'Header always set Access-Control-Allow-Headers': "*"
  // }

  // HttpClient API post() method => Create employee
  createEmployee(contact): Observable<Contact> {
    return this.http.post<Contact>(this.apiURL + '/api/contacts', JSON.stringify(contact), this.httpBasicAuthOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }



}
