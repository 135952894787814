/*
 * angly video
 * Used in another components.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[angly-chkConsultancy]',
  templateUrl: './chkConsultancy.component.html',
  styleUrls: ['./chkConsultancy.component.scss']
})
export class chkConsultancyComponent implements OnInit {

   @Input() consultancyContent : any;

   constructor() { }

   ngOnInit() {
   }

}
