import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor( private pageTitleService: PageTitleService, private service:ChkService ) {

    /* Page title */
    this.pageTitleService.setTitle(" Privacy Policy ");

    /* Page subTitle */
    // this.pageTitleService.setSubTitle(" Our latest news and learning articles. ");

  }

  ngOnInit() {
  }

}
