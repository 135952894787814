import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// Components
import { MainComponent }   from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { KiaComponent } from './projects/kia/kia.component';
import { JavaSpringBootComponent } from './our-services/java-spring-boot/java-spring-boot.component';
import { WebApplicationsComponent } from './our-services/web-applications/web-applications.component';
import { MobileApplicationsComponent } from './our-services/mobile-applications/mobile-applications.component';
import { ErpImplementationComponent } from './our-services/erp-implementation/erp-implementation.component';
import { TemenosComponent } from './our-services/temenos/temenos.component';
import { VirtualCtoComponent } from './our-services/virtual-cto/virtual-cto.component';
import { AiAndBiComponent } from './our-services/ai-and-bi/ai-and-bi.component';
import { DigitalMarketingComponent } from './our-services/digital-marketing/digital-marketing.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { SapAhanaComponent } from './our-services/sap-ahana/sap-ahana.component';
import { PowerBIComponent } from './our-services/power-bi/power-bi.component';
import { SalesContactComponent } from './widgets/contact/sales-contact/sales-contact.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { BusinessProcessMgmtComponent } from './our-services/business-process-mgmt/business-process-mgmt.component';
import {BigDataComponent} from './our-services/big-data/big-data.component';
import {TeamComponent} from './team/team.component';

// Products
import { ProductsComponent } from './products/products.component';
import { SportsERPComponent } from './products/sports-erp/sports-erp.component';
import { ManufacturingERPComponent } from './products/manufacturing-erp/manufacturing-erp.component';
import { GsuiteComponent } from './products/gsuite/gsuite.component';
import { LearningmgmtComponent } from './products/learningmgmt/learningmgmt.component';
import { EcommerceComponent } from './products/ecommerce/ecommerce.component';
import { CareersComponent } from './careers/careers.component';

// Services
import{ ProductEngineeringComponent } from './our-services/product-engineering/product-engineering.component';
import { TestComponent } from './test/test.component';

export const AppRoutes: Routes = [{
   path: '',
   redirectTo: 'home',
   pathMatch: 'full',
   },{
      path: '',
      component: MainComponent,
      children: [
         {
            path: 'home',
            component: HomeComponent
         },{
            path: '',
            loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
         },{
            path: 'pricing',
            component: PricingComponent
         },{
            path: 'contact',
            component: ContactComponent
         },{
            path:'features0932wq',
            component:FeaturesComponent
         },{
            path:'privacy',
            component:PrivacyComponent
         },{
            path:'terms',
            component:TermsComponent
         },{
            path:'about',
            component:AboutComponent
         },
         {
            path: 'team',
            component: TeamComponent
         },{
            path:'search',
            component:SearchComponent
         },{
            path:'support',
            component:SupportComponent
         },{
            path: '',
            loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule)
         }, {
            path: '',
            loadChildren: () => import('./testimonial/testimonial.module').then(m => m.TestimonialModule)
         }, {
            path: 'sidebar-widgets',
            component:sidebarWidgetsComponent
         },{
            path: '',
            loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
         },{
            path: '',
            loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
         },{
            path: 'about/:keyword',
            component: AboutComponent
			},{
            path:'kia-finance',
            component:KiaComponent
         },{
            path:'java-spring-boot',
            component:JavaSpringBootComponent
         },{
            path:'web-applications',
            component:WebApplicationsComponent
         },{
            path:'mobile-applications',
            component:MobileApplicationsComponent
         },{
            path:'erp-implementation',
            component:ErpImplementationComponent
         },{
          path:'temenos',
          component:TemenosComponent
         },{
          path:'virtual-cto',
          component:VirtualCtoComponent
         },{
            path:'ai',
            component:AiAndBiComponent
         },{
          path:'bigData',
          component:BigDataComponent
         },{
            path:'digital-marketing',
            component:DigitalMarketingComponent
         },{
            path:'our-services',
            component:OurServicesComponent
         },{
            path: 'sap-hana',
            component: SapAhanaComponent
         },{
            path:'powerBI',
            component: PowerBIComponent
         },{
            path:'sales',
            component: SalesContactComponent
         },
         {
            path:'business-process',
            component: BusinessProcessMgmtComponent
         },
         {
            path: 'products',
            component: ProductsComponent
         },
         {
            path: 'manufacturingERP',
            component: ManufacturingERPComponent
         },
         {
            path:'gsuite',
            component: GsuiteComponent
         },
         {
            path:'learning-management',
            component: LearningmgmtComponent
         },
         {
            path:'ecommerce',
            component: EcommerceComponent
         },
         {
            path: 'sportERP',
            component: SportsERPComponent
         },
         {
            path: 'careers',
            component: CareersComponent
         },
         {
            path:'product-engineering',
            component: ProductEngineeringComponent
         },
         // {
         //    path: 'test',
         //    component: TestComponent
         // }

      ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
