import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-subscription-letter',
  templateUrl: './subscription-letter.component.html',
  styleUrls: ['./subscription-letter.component.css']
})
export class SubscriptionLetterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  mySubscription(){
    console.log('Subscribed');
  }

}
