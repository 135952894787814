import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-learningmgmt',
  templateUrl: './learningmgmt.component.html',
  styleUrls: ['./learningmgmt.component.scss']
})
export class LearningmgmtComponent implements OnInit {

  /* Variables */
  title ='Learning Management - Encriss Technologies';
  featuresContent : any;
  springbootportfolio : any;
  springbootImage_path1="./assets/img/springboot-section2.png";

  constructor(
    private pageTitleService: PageTitleService,
    private service:ChkService,
    private titleService: Title,
    private meta: Meta
    ) {

    /* Page title */
    this.pageTitleService.setTitle(" Learning Management ");

    /* Page subTitle */
    // this.pageTitleService.setSubTitle(" Our latest news and learning articles. ");

    //  Background Image URL
   this.pageTitleService.setBgImg("../assets/img/products/learningmgmt/learning-banner.jpg");

    this.service.getManufacturingERPContent().
      subscribe(response => {this.featuresContent = response},
                err => console.log(err),
                () => this.featuresContent
            );
    

  }

  
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name:'keywords',
      content: 'web applications, web portal, front end developer, web design company, progressive web apps, web development company, backend developer, freelance web developer, website development company, web programming, web application development, web development services, progressive app, web design and development, front end web developer, freelance web designer, ecommerce website development, wordpress development company, website developers, web development agency, web based application, web app development, progressive web application, website design and development, wordpress website development, web application development company , ecommerce web design, website development services, web design and development company, best web design company, ecommerce website development company, wordpress web development, angular website, ecommerce web development, backend web development, web application development services, best web development company, web app development company, website design and development company'
    });
  }

}