/*
 * Home contact
 * Used in another component.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[angly-homeContact]',
  templateUrl: './homeContact.component.html',
  styleUrls: ['./homeContact.component.scss']
})
export class HomeContactComponent implements OnInit {

   @Input() contact : any;
  //  lat: number = 28.467478;
  //  lng: number = 77.081481;
   lat= 40.178873;
   lng= -11.821675;
   zoom:number = 2;
   draggable = false;
   gestureHandling: 'none';
   zoomControl: false

   coordinate=[
    {
      lat: 28.467478,
      lng: 77.081481,
      label:"C",
      place:"712, Galleria Towers, DLF Phase IV, Sector 28, Gurgaon, HR(122009)",
      number: "+91 98107 89998"
    },
    {
      lat: 49.286870,
      lng: -123.126740,
      label: "B",
      place: "1200, Alberni Street, Vancouver,BC(V6E 1A6)" ,
      number:"+1 (604) 339-4276"
    },
    {
      lat: 43.497390,
      lng: -80.547260,
      label: "A",
      place: "180 Northfield Dr W Unit 4, Waterloo, ON N2L 0C7, Canada" ,
      number:"+1 (519) 497-9283"
    }
  ];
   constructor() { }

   ngOnInit() {
   }

}
