import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-temenos',
  templateUrl: './temenos.component.html',
  styleUrls: ['./temenos.component.scss']
})
export class TemenosComponent implements OnInit {
/* Variables */
title = 'Temenos - Encriss Technologies';
featuresContent : any;

constructor(
  private pageTitleService: PageTitleService,
  private service:ChkService,
  private titleService: Title,
  private meta: Meta
  ) {

  /* Page title */
  this.pageTitleService.setTitle(" Temenos ");

   //  Background Image URL
   this.pageTitleService.setBgImg("../assets/img/services/temenos/temenosFeatured.png");

   this.service.getTemenosData().
   subscribe(response => {this.featuresContent = response},
             err => console.log(err),
             () => this.featuresContent
         );
}
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'keywords',
      content: 'temenos, erp implementation, Enterprise Resource Planning, enterprise resource planning system, enterprise resource planning software, erp softwares, erp enterprise resource planning, best erp, enterprise resource management, erp vendors, erp packages, erp system implementation, erp enterprise, successful erp implementation, enterprise resources, erp software implementation, best erp softwares, top erp softwares, successful erp implementation companies, erp implementation companies, erp implementation best practices, top erps,erp implementation cost, erp implementation strategy, erp implementation services, erp implementation project, enterprise resource planning company, types of erps, erp softwares list, types of erp softwares, cost of erp system, erp systems defined, enterprise planning tools'
    });
  }

}
