import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
key=0;
  constructor() { }

  dynamicTitleContent() {
    console.log("I am in");
    setTimeout(() => {
      console.log("Key: ",this.key);
      this.key++;
      if (this.key > this.ourServicesGridContent2.length) {
        this.key = 0;
      }
      this.dynamicTitleContent();
    // }, 2000);
    }, 7000);
  }
  ngOnInit() {
    
    this.dynamicTitleContent();
  }
  
  

  ourServicesGridContent2=[
    {
      serviceId:1,
       serviceName: 'Temenos',
      serviceRendered:[
        {
          serviceLink: '/temenos',
          imgLink:'./assets/img/services/temenos/temenos.png',
          title:'Temenos1',
          content:''
           },
           {
          serviceLink: '/virtual-cto',
          imgLink:'./assets/img/services/cto/products/14.png',
          title:'Virtual CTO1',
          content:''
           },
           {
          serviceLink: '/erp-implementation',
          imgLink:'./assets/img/services/erp/ERP.png',
          title:'ERP Implementation1',
          content:''
           }
      ]
    },
    {
      serviceId:2,
       serviceName: 'Virtual CTO',
      serviceRendered:[
        {
          serviceLink: '/temenos',
          imgLink:'./assets/img/services/temenos/temenos.png',
          title:'Temenos2',
          content:''
           },
           {
          serviceLink: '/virtual-cto',
          imgLink:'./assets/img/services/cto/products/14.png',
          title:'Virtual CTO2',
          content:''
           },
           {
          serviceLink: '/erp-implementation',
          imgLink:'./assets/img/services/erp/ERP.png',
          title:'ERP Implementation2',
          content:''
           }
      ]
    },
    {
      serviceId:3,
       serviceName: 'ERP _implementation',
      serviceRendered:[
        {
          serviceLink: '/temenos',
          imgLink:'./assets/img/services/temenos/temenos.png',
          title:'Temenos3',
          content:''
           },
           {
          serviceLink: '/virtual-cto',
          imgLink:'./assets/img/services/cto/products/14.png',
          title:'Virtual CTO3',
          content:''
           },
           {
          serviceLink: '/erp-implementation',
          imgLink:'./assets/img/services/erp/ERP.png',
          title:'ERP Implementation3',
          content:''
           }
      ]
    },
    {
      serviceId:4,
       serviceName: 'FIX Protocol',
      serviceRendered:[
        {
          serviceLink: '/temenos',
          imgLink:'./assets/img/services/temenos/temenos.png',
          title:'Temenos4',
          content:''
           },
           {
          serviceLink: '/virtual-cto',
          imgLink:'./assets/img/services/cto/products/14.png',
          title:'Virtual CTO4',
          content:''
           },
           {
          serviceLink: '/erp-implementation',
          imgLink:'./assets/img/services/erp/ERP.png',
          title:'ERP Implementation4',
          content:''
           }
      ]
    }
  
    
    ]
  ourServicesGridContent=[
    {
     serviceLink: '/temenos',
     imgLink:'./assets/img/services/temenos/temenos.png',
     title:'Temenos',
     content:''
    },
    {
     serviceLink: '/virtual-cto',
     imgLink:'./assets/img/services/cto/products/14.png',
     title:'Virtual CTO',
     content:''
    },
    {
     serviceLink: '/erp-implementation',
     imgLink:'./assets/img/services/erp/ERP.png',
     title:'ERP Implementation',
     content:''
    },
  {
   serviceLink: '/temenos',
   imgLink:'./assets/img/services/temenos/temenos.png',
   title:'Temenos',
   content:''
    },
    {
   serviceLink: '/virtual-cto',
   imgLink:'./assets/img/services/cto/products/14.png',
   title:'Virtual CTO',
   content:''
    },
    {
   serviceLink: '/erp-implementation',
   imgLink:'./assets/img/services/erp/ERP.png',
   title:'ERP Implementation',
   content:''
    },
    {
   serviceLink: '/temenos',
   imgLink:'./assets/img/services/temenos/temenos.png',
   title:'Temenos',
   content:''
    },
    {
   serviceLink: '/virtual-cto',
   imgLink:'./assets/img/services/cto/products/14.png',
   title:'Virtual CTO',
   content:''
    },
    {
   serviceLink: '/erp-implementation',
   imgLink:'./assets/img/services/erp/ERP.png',
   title:'ERP Implementation',
   content:''
    },
    {
   serviceLink: '/temenos',
   imgLink:'./assets/img/services/temenos/temenos.png',
   title:'Temenos',
   content:''
    },
    {
   serviceLink: '/virtual-cto',
   imgLink:'./assets/img/services/cto/products/14.png',
   title:'Virtual CTO',
   content:''
    },
    {
   serviceLink: '/erp-implementation',
   imgLink:'./assets/img/services/erp/ERP.png',
   title:'ERP Implementation',
   content:''
    },
    {
   serviceLink: '/temenos',
   imgLink:'./assets/img/services/temenos/temenos.png',
   title:'Temenos',
   content:''
    },
    {
   serviceLink: '/virtual-cto',
   imgLink:'./assets/img/services/cto/products/14.png',
   title:'Virtual CTO',
   content:''
    },
    {
   serviceLink: '/erp-implementation',
   imgLink:'./assets/img/services/erp/ERP.png',
   title:'ERP Implementation',
   content:''
    }
  ]
}
