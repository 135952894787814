import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor( private pageTitleService: PageTitleService, private service:ChkService ) {

    /* Page title */
    this.pageTitleService.setTitle(" Terms & Conditions ");

    /* Page subTitle */
    // this.pageTitleService.setSubTitle(" Our latest news and learning articles. ");

  }


  ngOnInit() {
  }

}
