import { Component, OnInit, OnDestroy, ViewChild, HostListener, Inject, ElementRef, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
declare var $ : any;

@Component({
    selector: 'angly-layout',
  	templateUrl:'./main.component.html',
  	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit{

	 private _router: Subscription;
	 url: string;
	 private _mediaSubscription: Subscription;
	 private _routerEventsSubscription: Subscription;
	 isHome : boolean = true;
	 isBlog : boolean = false;
	 isCommon : boolean = false;
	 fixedHeaderClass : boolean = false;

	/* Variables */
	headerTitle    : string;
	headerSubTitle : string;
	headerbgImgUrl: string;
	featuredPost   : any;
	key = 0;

	constructor(private pageTitleService: PageTitleService, private service:ChkService, public router: Router) {

		/* page title.*/
			this.pageTitleService.title.subscribe((val: string) => {
			this.headerTitle = val;
		});

		/* page subTitle. */
			this.pageTitleService.subTitle.subscribe((val: string) => {
			this.headerSubTitle = val;
		});
		/* page title background image.*/
		this.pageTitleService.bgImgUrl.subscribe((val: string) => {
			this.headerbgImgUrl = val;
		});

		this.service.getFeaturedPost().
			subscribe(response => {this.featuredPost = response},
			          err    => console.log(err),
			          ()     => this.featuredPost
			       );
	}

	ngOnInit() {
		this.dynamicTitleContent();
		// let newVideo = <HTMLVideoElement> document.getElementById('background-video');
		// 	// console.log(newVideo);
		// 	newVideo.muted= true;
		// 	newVideo.play();
	 }

	@HostListener('scroll', ['$event'])
	onScroll(event) {
		  if(event.path && (event.path[0].scrollTop > 0)){
		  		this.fixedHeaderClass = true;
		  }else{
		  		this.fixedHeaderClass = false;
		  }
	}

	onActivate(e, scrollContainer) {
		scrollContainer.scrollTop = 0;
		window.scroll(0,0);
   }

   addToggleClass() {
   	$('body').toggleClass('rtl-enable');
   }

   ourServicesGridContent=[
     {
      serviceLink: '/temenos',
      imgLink:'./assets/img/services/temenos/temenos.png',
      title:'Temenos',
      content:''
     },
     {
      serviceLink: '/virtual-cto',
      imgLink:'./assets/img/services/cto/products/14.png',
      title:'Virtual CTO',
      content:''
     },
     {
      serviceLink: '/erp-implementation',
      imgLink:'./assets/img/services/erp/ERP.png',
      title:'ERP Implementation',
      content:''
     },
	 {
		serviceLink: '/temenos',
		imgLink:'./assets/img/services/temenos/temenos.png',
		title:'Temenos',
		content:''
	   },
	   {
		serviceLink: '/virtual-cto',
		imgLink:'./assets/img/services/cto/products/14.png',
		title:'Virtual CTO',
		content:''
	   },
	   {
		serviceLink: '/erp-implementation',
		imgLink:'./assets/img/services/erp/ERP.png',
		title:'ERP Implementation',
		content:''
	   },
	   {
		serviceLink: '/temenos',
		imgLink:'./assets/img/services/temenos/temenos.png',
		title:'Temenos',
		content:''
	   },
	   {
		serviceLink: '/virtual-cto',
		imgLink:'./assets/img/services/cto/products/14.png',
		title:'Virtual CTO',
		content:''
	   },
	   {
		serviceLink: '/erp-implementation',
		imgLink:'./assets/img/services/erp/ERP.png',
		title:'ERP Implementation',
		content:''
	   },
	   {
		serviceLink: '/temenos',
		imgLink:'./assets/img/services/temenos/temenos.png',
		title:'Temenos',
		content:''
	   },
	   {
		serviceLink: '/virtual-cto',
		imgLink:'./assets/img/services/cto/products/14.png',
		title:'Virtual CTO',
		content:''
	   },
	   {
		serviceLink: '/erp-implementation',
		imgLink:'./assets/img/services/erp/ERP.png',
		title:'ERP Implementation',
		content:''
	   },
	   {
		serviceLink: '/temenos',
		imgLink:'./assets/img/services/temenos/temenos.png',
		title:'Temenos',
		content:''
	   },
	   {
		serviceLink: '/virtual-cto',
		imgLink:'./assets/img/services/cto/products/14.png',
		title:'Virtual CTO',
		content:''
	   },
	   {
		serviceLink: '/erp-implementation',
		imgLink:'./assets/img/services/erp/ERP.png',
		title:'ERP Implementation',
		content:''
	   }
   ]

   capture(event){
	   console.log("Text changed to: ",event);
   }

   dynamicTitleContent() {
	// console.log(this.ourServicesGridContent2.length);
    console.log("I am in");
	console.log("Key: ",this.key);
	if (this.key >= this.ourServicesGridContent2.length) {
        this.key = 0;		
    }
	setTimeout(() => {
		this.key++;
		this.dynamicTitleContent();
	//   }, 2000);
	  }, 7000);
  
  }

   ourServicesGridContent2=[
	{
		serviceId:0,
	 	serviceName: 'Temenos',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos0',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO0',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation0',
				content:''
			   }
		]
	},
	{
		serviceId:1,
	 	serviceName: 'Virtual CTO',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos1',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO1',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation1',
				content:''
			   }
		]
	},
	{
		serviceId:2,
	 	serviceName: 'ERP _implementation',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos2',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO2',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation2',
				content:''
			   }
		]
	},
	{
		serviceId:3,
	 	serviceName: 'FIX Protocol',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos3',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO3',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation3',
				content:''
			   }
		]
	},
	{
		serviceId:4,
	 	serviceName: 'Artificial Intelligence',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos4',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO4',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation4',
				content:''
			   }
		]
	},
	{
		serviceId:5,
	 	serviceName: 'Web Applications',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos5',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO5',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation5',
				content:''
			   }
		]
	},
	{
		serviceId:6,
	 	serviceName: 'Mobile Apps',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos6',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO6',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation6',
				content:''
			   }
		]
	},
	{
		serviceId:7,
	 	serviceName: 'Digital Marketing',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos7',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO7',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation7',
				content:''
			   }
		]
	},
	{
		serviceId:8,
	 	serviceName: 'Business Intelligence',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos8',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO8',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation8',
				content:''
			   }
		]
	},
	{
		serviceId:9,
	 	serviceName: 'Consulting Services',
		serviceRendered:[
			{
				serviceLink: '/temenos',
				imgLink:'./assets/img/services/temenos/temenos.png',
				title:'Temenos9',
				content:''
			   },
			   {
				serviceLink: '/virtual-cto',
				imgLink:'./assets/img/services/cto/products/14.png',
				title:'Virtual CTO9',
				content:''
			   },
			   {
				serviceLink: '/erp-implementation',
				imgLink:'./assets/img/services/erp/ERP.png',
				title:'ERP Implementation9',
				content:''
			   }
		]
	},

	
  ]
}
