import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: '[app-our-services-page-grid]',
  templateUrl: './our-services-page-grid.component.html',
  styleUrls: ['./our-services-page-grid.component.css']
})
export class OurServicesPageGridComponent implements OnInit {

  @Input() ourServicesGridContent:any;
  constructor() { }

  ngOnInit() {
  }

}
