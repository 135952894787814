import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SlickModule } from 'ngx-slick';
import { DirectivesModule } from './core/directive/directives.module';
import { AgmCoreModule } from '@agm/core';
/* Routing */
import { AppRoutingModule } from './app-routing.module';

/* Service */
import { ChkService } from './service/chk.service';

/* components */
import { AppComponent } from './app.component';
import { MainComponent }   from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';

import { MenuItems } from './core/menu/menu-items/menu-items';
import { MenuToggleModule } from './core/menu-toggle.module';
import { PageTitleService } from './core/page-title/page-title.service';
import { WidgetsModule } from './widgets/widgets.module';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { Footer2Component } from './footer2/footer2.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { KiaComponent } from './projects/kia/kia.component';
import { JavaSpringBootComponent } from './our-services/java-spring-boot/java-spring-boot.component';
import { WebApplicationsComponent } from './our-services/web-applications/web-applications.component';
import { MobileApplicationsComponent } from './our-services/mobile-applications/mobile-applications.component';
import { ErpImplementationComponent } from './our-services/erp-implementation/erp-implementation.component';
import { AiAndBiComponent } from './our-services/ai-and-bi/ai-and-bi.component';
import { DigitalMarketingComponent } from './our-services/digital-marketing/digital-marketing.component';
import { SubscriptionLetterComponent } from './subscription-letter/subscription-letter.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { SapAhanaComponent } from './our-services/sap-ahana/sap-ahana.component';
import { PowerBIComponent } from './our-services/power-bi/power-bi.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { BusinessProcessMgmtComponent } from './our-services/business-process-mgmt/business-process-mgmt.component';
import { ProductsComponent } from './products/products.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ResourcesComponent } from './resources/resources.component';
import { CareersComponent } from './careers/careers.component';
import { ManufacturingERPComponent } from './products/manufacturing-erp/manufacturing-erp.component';
import { GsuiteComponent } from './products/gsuite/gsuite.component';
import { LearningmgmtComponent } from './products/learningmgmt/learningmgmt.component';
import { EcommerceComponent } from './products/ecommerce/ecommerce.component';
import { SportsERPComponent } from './products/sports-erp/sports-erp.component';
import { ProductEngineeringComponent } from './our-services/product-engineering/product-engineering.component';
import { VirtualCtoComponent } from './our-services/virtual-cto/virtual-cto.component';
import { TemenosComponent } from './our-services/temenos/temenos.component';
// import { MatToolbarModule } from '@angular/material';
import {TeamComponent} from './team/team.component';


import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule } from '@angular/material/dialog';
import { BigDataComponent } from './our-services/big-data/big-data.component';
import { TestComponent } from './test/test.component';

@NgModule({
   declarations: [
      AppComponent,
      MainComponent,
      HomeComponent,
      PricingComponent,
      ContactComponent,
      FooterComponent,
      HeaderComponent,
      MenuComponent,
      FeaturesComponent,
      AboutComponent,
      SearchComponent,
      SupportComponent,
      Footer2Component,
      sidebarWidgetsComponent,
      KiaComponent,
      JavaSpringBootComponent,
      WebApplicationsComponent,
      MobileApplicationsComponent,
      ErpImplementationComponent,
      AiAndBiComponent,
      DigitalMarketingComponent,
      SubscriptionLetterComponent,
      OurServicesComponent,
      SapAhanaComponent,
      PowerBIComponent,
      PrivacyComponent,
      TermsComponent,
      BusinessProcessMgmtComponent,
      ProductsComponent,
      SolutionsComponent,
      ResourcesComponent,
      CareersComponent,
      ManufacturingERPComponent,
      GsuiteComponent,
      LearningmgmtComponent,
      EcommerceComponent,
      SportsERPComponent,
      ProductEngineeringComponent,
      VirtualCtoComponent,
      TemenosComponent,
      BigDataComponent,
      TeamComponent,
      TestComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientJsonpModule, HttpClientModule,
      AppRoutingModule,
      WidgetsModule,
      MenuToggleModule,
      DirectivesModule,
      // MatToolbarModule,
      MatDialogModule, MatInputModule, MatButtonModule, MatCardModule, MatFormFieldModule,
      SlickModule.forRoot(),
      AgmCoreModule.forRoot({
         apiKey: 'AIzaSyCehiheHtGIKnlCtYzHZwADvnSvhlcxeAc'
      })
   ],
   providers: [
      MenuItems,
      PageTitleService,
      ChkService
   ],
   bootstrap: [AppComponent]
})
export class AppModule { }
