import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { PageTitleService } from '../../../core/page-title/page-title.service';
import { FormControl, FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-sales-contact',
  templateUrl: './sales-contact.component.html',
  styleUrls: ['./sales-contact.component.scss']
})
export class SalesContactComponent implements OnInit {

  defaultQuestion="pet";

  onSubmit(form:NgForm){
    console.log(form);
  }

  // onSubmit(form:NgForm){
  //   console.log(this.contactForm);
  // }







  sendMessageForm : FormGroup;

   constructor(private pageTitleService: PageTitleService ,private formBuilder : FormBuilder, private http: HttpClient) {

      /* Page title */
      this.pageTitleService.setTitle(" Sales Partners ");

      this.sendMessageForm = this.formBuilder.group({
         first_name   : [null, [Validators.required] ],
         last_name    : [null, [Validators.required] ],
         email        : [, [Validators.required] ],
         wphone       : [null, [Validators.required] ],
         cname        : [null, [Validators.required] ],
         businessLine : [null, [Validators.required] ],
         clientNumber : [null, [Validators.required] ],
         experience   : [null, [Validators.required] ],
         textarea     : [null, [Validators.required] ]

      });
   }

  ngOnInit() {
  }
  /*
    * sendMessage
    */
   sendMessage(values:any)
   {
     if(this.sendMessageForm.valid)
     {
      this.http.post('https://mailthis.to/bijayanand@encriss.com', this.sendMessageForm).subscribe(
        (response) => console.log(response),
        (error) => console.log(error)
      )
       console.log(values);
     } else{
       console.log("Error!");
     }
   }
}
